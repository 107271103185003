import { pushEventData, setCustomDimension, trackEvent } from './MatomoUtils';
import {
    MATOMO_DIMENSION_ORIENTATION, MATOMO_LAYOUT_DIMENSION, MATOMO_FULLSCREEN_DIMENSION,
    MATOMO_DIMENSION_LOGIN, MATOMO_VISIT_DIMENSION_LOGIN, MATOMO_TRACK_LAYER_CHANGE
} from './TrackingConstants';

import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { stripHash } from '@egr/xbox/utils/Url';

import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export function updateOrientation(): void {
    const orientation: string = window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape';
    setCustomDimension(MATOMO_DIMENSION_ORIENTATION, orientation);
}

export function setLayoutDimension(isDarkmode: boolean): void {
    setCustomDimension(MATOMO_LAYOUT_DIMENSION, isDarkmode ? 'darkmode' : 'lightmode');
}

export function setFullscreenDimension(isFullscreen: boolean): void {
    setCustomDimension(MATOMO_FULLSCREEN_DIMENSION, isFullscreen ? 'full screen' : 'default');
}

const LoginDimensionFallbackValue: string = 'no_login';

export function updateLoginDimension(name?: string): void {
    if (isNotNullOrEmpty(name)) {
        setCustomDimension(MATOMO_DIMENSION_LOGIN, name);
        setCustomDimension(MATOMO_VISIT_DIMENSION_LOGIN, name);
    } else {
        setCustomDimension(MATOMO_DIMENSION_LOGIN, LoginDimensionFallbackValue);
        setCustomDimension(MATOMO_VISIT_DIMENSION_LOGIN, LoginDimensionFallbackValue);
    }
    pushEventData(['trackPageView']); // need to be called to track changed/new customDimension values
}

/**
 * Sets custom url to current url + /view if env MATOMO_TRACK_LAYER_CHANGE is set
 * @param view
 */
export function updateCustomUrl(view?: string): void {
    let customUrl: string = location.href;

    if (appInfo?.app_caching?.active === true) {
        customUrl = appInfo.initialization_url;
    }

    customUrl = stripHash(customUrl);

    if (!MATOMO_TRACK_LAYER_CHANGE || isNullOrEmpty(view)) {
        pushEventData(['setCustomUrl', customUrl]);
    } else {
        pushEventData(['setCustomUrl', customUrl + '/' + view]);
        pushEventData(['setDocumentTitle', view]);
    }

    pushEventData(['trackPageView']);
}

export function trackSearch(term: string, category: string, results: number): void {
    if (isNotNullOrEmpty(term)) {
        pushEventData(['trackSiteSearch', term, category, results]);
    }
}

export function setDownloadExtensions(values: Set<string>): void {
    pushEventData(['setDownloadExtensions', Array.from(values).join('|')]);
}

export function trackTaxSetting(
    isGlobal: boolean,
    countryCode: string,
    visibility: boolean | undefined
): void {
    trackEvent({
        category: isGlobal ? 'Global settings' : 'Article list settings',
        action: 'taxes',
        name: countryCode === 'ZZ' ? 'custom' : countryCode,
    });

    if (visibility != null) {
        trackEvent({
            category: isGlobal ? 'Global settings' : 'Article list settings',
            action: 'taxes',
            name: visibility ? 'Price-with-VAT' : 'Price-without-VAT'
        });
    }
}

export function trackAddArticle(
    sourceType: 'catalog-article' | 'list-article',
    manufacturerId: string
): void {
    trackEvent({
        category: 'Article',
        action: 'add_manufacturer_to_list',
        name: manufacturerId,
    });

    trackEvent({
        category: 'Article',
        action: 'add_to_list',
        name: sourceType
    });
}

export function trackPIMCategory(category: string, isCustomCategory: boolean): void {
    trackEvent({
        category: isCustomCategory ? 'PIM Individual' : 'PIM',
        action: 'open',
        name: category
    });
}