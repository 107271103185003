import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import type { BackendModule, ResourceLanguage } from 'i18next';

import type { AppProps } from './App';
import { ALLOW_CUSTOM_POOL, GATEKEEPER_ID } from './Constants';
import { AppLoader } from './AppLoader';
import { DisableCloud, EaiwsStartup } from './DefaultValues';
import { SessionManager as PconUiSessionManager } from './managers/SessionManager';
import { loadUiSettings } from './utils/UiSettings';

import { EAIWS_SERVER } from '@egr/xbox/utils/Constants';
import { GatekeeperManager } from '@egr/xbox/base-app/managers/GatekeeperManager';
import { StaticEaiwsManager } from '@egr/xbox/base-app/managers/StaticEaiwsServer';
import { addGatekeeperIdHook } from '@egr/xbox/base-app/hooks/GatekeeperId';
import { getEnvBoolean, PUBLIC_URL } from '@egr/xbox/utils/ReactScriptHelper';
import { initInstance, USER_LANGUAGES } from '@egr/xbox/base-app/i18n';
import { printTimer } from '@egr/xbox/utils/Debug';
import { enableSentry, setupSentry, updateTag } from '@egr/xbox/utils/Error';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { wcfConfig } from '@easterngraphics/wcf/modules/utils';

const DEBUG_MOBX: boolean = getEnvBoolean('DEBUG_MOBX', false);

printTimer('Start', 'START');
printTimer('BeforeConfiguration', 'START');

configure({
    enforceActions: 'observed',
    computedRequiresReaction: DEBUG_MOBX,
    reactionRequiresObservable: DEBUG_MOBX,
    observableRequiresReaction: DEBUG_MOBX,
    disableErrorBoundaries: DEBUG_MOBX
});

enableSentry(true);
setupSentry();

function getEvaluationLicensedInformation(): boolean {
    const allowedDomains: Array<RegExp> = [
        /(https?:\/\/(.+?\.)?pcon\.eu)/,
        /(https?:\/\/(.+?\.)?easterngraphics\.com)/,
        /(https?:\/\/(.+?\.)?pcon-solutions\.com)/
    ];
    return allowedDomains.some((value) => { return value.test(EAIWS_SERVER ?? ''); });
}

function customBackend(importBaseUrl?: string): BackendModule {
    return {
        type: 'backend',
        init: () => {/**/},
        create: () => {/**/},
        async read(language: string, namespace: string, callback: (err: Error | null | undefined, data: ResourceLanguage) => void): Promise<void> {
            try {
                const appTranslation: ResourceLanguage = await import(
                    /* webpackChunkName: "i18n" */
                    `./translations/${language}/${namespace}.json`
                );

                const xboxTranslation: ResourceLanguage = await import(
                    /* webpackChunkName: "i18n" */
                    `./submodules/translations/${language}/${namespace}.json`
                );

                let translation: ResourceLanguage = Object.assign({}, xboxTranslation, appTranslation);

                if (isNotNullOrEmpty(importBaseUrl)) {
                    const importUrl = `${importBaseUrl}/${language}.json`;
                    try {
                        const request = await fetch(importUrl);
                        const content = await request.json();
                        translation = Object.assign({}, translation, content);
                    } catch {
                        console.warn(`failed to load language ressources of ${language} from ${importUrl}`);
                    }
                }

                callback(null, translation);
            } catch (error) {
                callback(error, null!);
            }
        }
    };
}

async function run(): Promise<void> {
    wcfConfig.dataPath = PUBLIC_URL + '/w-cf/data/';

    let gatekeeperId: string = DisableCloud ? '' : GATEKEEPER_ID;
    if (!ALLOW_CUSTOM_POOL && gatekeeperId.indexOf(':') !== -1) {
        gatekeeperId = gatekeeperId.split(':')[0];
    }

    const props = await getProps(gatekeeperId);

    await initInstance(
        USER_LANGUAGES,
        props.uiManagerState?.settings.general.lang[0],
        customBackend(props.uiManagerState?.settings.general.translationsImportUrl)
    );

    const app: JSX.Element = (
        <AppLoader
            {...props}
            loadIframeResizer={Boolean(
                !DisableCloud &&
                props.uiManagerState?.settings?.general.enableIframeResizer &&
                window.self !== window.top
            )}
        />
    );

    const rootElement = document.getElementById('root');

    if (rootElement == null) {
        throw new Error('root element not found');
    }

    const root = createRoot(rootElement);

    root.render(app);
}

async function getProps(gatekeeperId: string): Promise<AppProps> {
    const isEvaluationLicensed = getEvaluationLicensedInformation();

    updateTag('gatekeeperId', gatekeeperId);

    if (isNotNullOrEmpty(EAIWS_SERVER) && isNotNullOrEmpty(EaiwsStartup) && !isEvaluationLicensed) {
        addGatekeeperIdHook(async () => { return 'null'; /* will cause intended "unlicensed error" */ });
        return {
            gatekeeperId,
            sessionManager: new GatekeeperManager()
        };
    } else {
        const uiManagerState = await loadUiSettings(gatekeeperId);
        if (!uiManagerState) {
            addGatekeeperIdHook(async () => { return 'null'; /* will cause intended "unlicensed error" */ });
            return {
                gatekeeperId,
                sessionManager: new GatekeeperManager(),
            };
        } else if (isNotNullOrEmpty(EAIWS_SERVER) && isNotNullOrEmpty(EaiwsStartup)) {
            return {
                gatekeeperId,
                sessionManager: new StaticEaiwsManager(undefined),
                uiManagerState
            };
        } else {
            addGatekeeperIdHook(async () => { return gatekeeperId; });
            return {
                gatekeeperId,
                sessionManager: new PconUiSessionManager(uiManagerState.settings.general.lang),
                uiManagerState
            };
        }
    }

}

void run();