import bind from 'bind-decorator';
import merge from 'lodash/merge';
import type { Theme } from '@mui/material/styles';
import { computed, reaction, makeObservable, toJS } from 'mobx';

import App from '../App';
import {
    CSSImport, CurrencyLeft,
    IsRevit, iframeHeightSmall,
    LanguageMapping,
    ManufacturerInSubInfo,
    PaletteImport, pim, pimJSON,
    SeriesInSubInfo,
    ArticleNoInSubInfo, ForceCatalogRoot, CatalogRootPath, CatalogEntryPath,
} from '../DefaultValues';
import { ButtonTypes, UiButton, getAllButtons, getFooterButtons, getPopoverButtons, getTopButtons } from '../utils/UiSettings';

import type { GeometryExportFormatKey } from '@egr/xbox/utils/FileFormats';
import { AppStateManager } from '@egr/xbox/base-app/managers/Manager';
import { breakpointObserver } from '@egr/xbox/egr-gui-elements/Helper/WindowSize';
import { getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';
import { iOSQuicklookArAvailable, android } from '@egr/xbox/utils/UserAgent';
import { methodWithDebugTimer } from '@egr/xbox/utils/Debug';
import { setDefaultCurrencyStyle, CurrencyStyle } from '@egr/xbox/egr-gui-utils/StringHelper';
import { setLanguage } from '@egr/xbox/base-app/i18n';

import { BoundingBox, SceneElement } from '@easterngraphics/wcf/modules/core/mdl';
import { LengthUnit } from '@easterngraphics/wcf/modules/utils/number';
import { MainArticleElement } from '@easterngraphics/wcf/modules/cf';
import { contains } from '@easterngraphics/wcf/modules/utils/array';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { degToRad } from '@easterngraphics/wcf/modules/utils/math';
import { safelyParseJSON } from '../submodules/utils/Json';

const ALL_BUTTONS_IN_FULLSCREEN: boolean = getEnvBoolean('ALL_BUTTONS_IN_FULLSCREEN', false);

export enum UiThemeType {
    DEFAULT,
    LIGHT,
    DARK,
    VITRA
}

type OciVersion = '4' | '5';

export interface UiPermissions {
    pricing: boolean;
    cadFormats: null | Array<GeometryExportFormatKey>;
    allowMultipleManufacturerPEC: boolean;
    ociExport: boolean;
    pdfDownload: boolean;
    iframeAllowedParents: null | Array<string>;
    postMessageContactForm: boolean;
    showUrlsInCatalog: boolean;
}

interface GeneralSettings {
    lang: Array<string>;
    ofmlLang?: string;
    translationsImportUrl?: string;
    lengthUnit: string;
    pdfTemplate: string;
    catalogRootAllowed: boolean;
    userTracking: boolean;
    customParentTracking: boolean;
    enableIframeResizer: boolean;
    fullscreenOnly: boolean;
    ociVersion: OciVersion;
    useOldOci: boolean;
    generateContactRequestPdf: boolean;
    propertyServiceUrl: string | undefined;
    pim: boolean;
    pimJSON: boolean;
    migrateArticles: boolean;
}

interface PricingSettings {
    calculationScheme: string;
    tax: string;
    currency: string;
    currencyLeft: boolean;
    showPrice: boolean;
    netPrice: boolean;
    priceServiceUrl: string | undefined;
}

interface GeneralVisibility {
    header: boolean;
}

export interface UiVisibility {
    general: GeneralVisibility;
    articleData: ArticleDataVisibility;
    buttons: Array<UiButton>;
}

interface ArticleDataVisibility {
    description: boolean;
    manufacturerInSubInfo: boolean;
    seriesInSubInfo: boolean;
    articleNoInSubInfo: boolean;
    showUneditableProperties: boolean;
    enableMetatypeProperties: boolean;
    showArticleName: boolean;
    showPropertyHeaders: boolean;
}

export interface UiSettings {
    general: GeneralSettings;
    pricing: PricingSettings;
    report: ReportSettings
    camera: UiCameraSettings;
}

export interface UiCameraSettings {
    restrictRotationToZAxis: boolean;
    lockToTarget: boolean;
    alpha: number | null;
    beta: number | null;
    fov: number | undefined;
}

interface ReportSettings {
    templateName: string;
    enablePricing: boolean;
    enforcePricing: boolean;
    footerText: string;
    logoUrl: string;
}

interface UiDefaults {
    lang: Array<string>;
    showPrice: boolean;
    netPrice: boolean;
    header: boolean;
    fullscreenOnly: boolean;
    restrictRotationToZAxis: boolean;
    lockToTarget: boolean;
    description: boolean;
    manufacturerInSubInfo: boolean;
    seriesInSubInfo: boolean;
    articleNoInSubInfo: boolean;
    showArticleName: boolean;
    showPropertyHeaders: boolean;
    showUneditableProperties: boolean;
    enableMetatypeProperties: boolean;
}

export const enum PropertyEditorVariant {
    /** default value */
    FULL = 'full',
    COMPACT = 'compact',
}

export const enum ArticleDrawerVariant {
    /** default value */
    SINGLE = 'single',
    MODULAR = 'modular',
}

export interface UiStyles {
    cssImport: string | undefined;
    paletteImport: string | undefined;
    mainPrimaryColor: string | undefined;
    background: 'radiantgrey' | 'black' | 'white';
    iframeHeightSmall: string;
    /**
     * overrides label of "Send Request" and "Add to cart" buttons
     */
    buttonText?: string;
    /**
     * Will be set after optional JSON was loaded
     */
    paletteOverride?: Partial<Theme['palette']>;
    articleDrawerVariant?: ArticleDrawerVariant;
    propertyEditorVariant?: PropertyEditorVariant;
}

export interface UiManagerState {
    active: boolean;
    gatekeeperId: string | null;
    permissions: UiPermissions;
    settings: UiSettings;
    visibility: UiVisibility;
    defaults: UiDefaults;
    theme: UiThemeType;
    styles: UiStyles;
}

export class UiManager extends AppStateManager<UiManagerState, App> {
    constructor() {
        super();
        makeObservable(this);
        this.lockCameraToFixedTarget = this.lockCameraToFixedTarget.bind(this);
        this.restrictRotationToZAxis = this.restrictRotationToZAxis.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.shouldSkipActivation = this.shouldSkipActivation.bind(this);
    }

    @computed
    public get shouldShowNoScrollbars(): boolean {
        return (
            this.state.settings.general.enableIframeResizer &&
            this.state.styles.iframeHeightSmall === 'auto' &&
            breakpointObserver.smallLayout
        );
    }

    public get lsCacheGatekeeperIdKey(): string {
        return `${this.state.gatekeeperId ?? 'null'}-hookUrl`;
    }

    /**
     * Adds required buttons to setting if no setting for that button is provided
     */
    @computed
    public get fullDefaultButtonsList(): Array<UiButton> {
        // this way no exceptions are thrown if a component accesses this before UiManager.initialize was called
        if (!this.appAvailable) {
            return [];
        }

        const requiredButtons: Array<ButtonTypes | null> = [
            this.state.settings.general.fullscreenOnly ? null : 'fullscreen',
            'pdf',
            this.app.articleInformationManager.state.fallbackImage != null && (iOSQuicklookArAvailable || android) ? 'ar' : null,
            (this.state.permissions.cadFormats?.length && this.showDownloadCAD) ? 'cad' : null
        ];

        const result: Array<UiButton> = getAllButtons(
            toJS(this.state.visibility.buttons),
            requiredButtons,
            this.state.settings.general.fullscreenOnly,
            this.app.sceneManager.state.disableInteractors,
            this.state.styles.articleDrawerVariant === ArticleDrawerVariant.MODULAR,
        );

        if (this.app.sceneManager.state.fullscreen) {
            return result.filter(this.filterButtonsForFullscreen);
        }

        return result;
    }

    @computed
    public get topButtons(): Array<UiButton> {
        if (!this.appAvailable) {
            return [];
        }

        const result: Array<UiButton> = Array.from(getTopButtons(
            this.fullDefaultButtonsList,
            Boolean(this.app.propertyEditorManager.selectionHasInteractors && !this.app.viewManager.state.restrictedContent),
            this.showDownloadCAD
        ));

        if (this.app.sceneManager.state.fullscreen) {
            return result.filter(this.filterButtonsForFullscreen);
        }

        return result;
    }

    @computed
    public get footerButtons(): Array<UiButton> {
        if (!this.appAvailable) {
            return [];
        }

        return Array.from(getFooterButtons(
            this.fullDefaultButtonsList,
            Boolean(this.app.propertyEditorManager.selectionHasInteractors && !this.app.viewManager.state.restrictedContent),
            this.showDownloadCAD
        ));
    }

    /**
     * Will be shown in popover on top of the scene
     */
    @computed
    public get popoverButtons(): Array<UiButton> {
        if (!this.appAvailable) {
            return [];
        }

        const result: Array<UiButton> = Array.from(getPopoverButtons(
            this.fullDefaultButtonsList,
            Boolean(this.app.propertyEditorManager.selectionHasInteractors && !this.app.viewManager.state.restrictedContent),
            this.showDownloadCAD
        ));

        if (this.app.sceneManager.state.fullscreen) {
            return result.filter(this.filterButtonsForFullscreen);
        }

        return result;
    }

    @computed
    public get showDownloadCAD(): boolean {
        return (
            getEnvBoolean('cad', true) &&
            this.app.articleInformationManager.state.fallbackImage == null &&
            (this.state.permissions.cadFormats?.length ?? 0) > 0 &&
            !(IsRevit && !contains(this.state.permissions.cadFormats ?? [], 'RVT')) &&
            (!this.app.viewManager.state.restrictedContent)
        );
    }

    public async setLanguage(language: string): Promise<void> {
        await setLanguage(language);
    }

    public async setEaiwsDataLanguage(language?: string): Promise<void> {
        if (language == null) {
            language = this.state.settings.general.ofmlLang ?? this.state.settings.general.lang[0];
        }
        void this.app.sessionManager.setLanguage(language);
        void this.app.eaiwsSession.session.setLocale(LanguageMapping[language].locale);
    }

    @methodWithDebugTimer('UIManager: setEAIWSCurrency')
    public async setEaiwsCurrency(): Promise<void> {
        if (this.app.eaiwsBasket.calculation.pricingProcedure) {
            void this.app.eaiwsSession.basket.setCurrency(this.state.settings.pricing.currency);
            void this.app.eaiwsSession.basket.selectCurrentTaxScheme(this.state.settings.pricing.tax);
            if (this.app.pricesManager.state.priceProcedure === 'STDB2B_FACTS_NR') {
                void this.app.eaiwsSession.basket.setConditionAmount(
                    [''],
                    this.app.pricesManager.state.priceProcedure,
                    'RND00',
                    -1,
                    0.01,
                    this.state.settings.pricing.currency
                );
            }
        }
    }

    public setLengthUnit(): void {
        const unitsMap = new Map<string, LengthUnit>([
            ['Millimeter', 0],
            ['Centimeter', 1],
            ['Inch', 2],
            ['Feet', 3],
            ['Meter', 4],
            ['Kilometer', 5]
        ]);
        this.app.coreApp.i18n.setLengthUnit(unitsMap.get(this.state.settings.general.lengthUnit) ?? 0);
    }

    public override initialize(app: App, uiAppSettings: UiManagerState | undefined): void {
        super.initialize(app);

        if (this.shouldSkipActivation(uiAppSettings)) {
            return this.updateState({active: false});
        }

        this.initializeState(uiAppSettings);

        if (this.app.sceneManager.viewerCreated) {
            this.onViewCreated();
        } else {
            app.events.viewCreated.addListener(this.onViewCreated);
        }

        this.updateCurrencyStyle(this.state.settings?.pricing?.currencyLeft ?? false);

        reaction(
            () => {
                return this.state.settings?.pricing?.currencyLeft ?? false;
            },
            this.updateCurrencyStyle
        );
    }

    public setPriceState(): void {
        if (!this.state.settings.pricing.showPrice) {
            this.app.pricesManager.initState({
                showPrices: false
            });
        } else {
            this.app.pricesManager.initState({
                showPrices: true,
                exclusiveTaxes: this.state.settings.pricing.netPrice,
                currency: this.state.settings.pricing.currency,
            });
        }
    }

    public isValidTextOverride(value: string | undefined): value is string {
        return isNotNullOrEmpty(value) && value.length <= 25;
    }

    protected iframeSourceValid(uiAppSettings: UiManagerState | undefined): uiAppSettings is UiManagerState {
        if (uiAppSettings == null) {
            return false;
        }

        if (parent !== window && uiAppSettings.permissions.iframeAllowedParents != null) {
            const regex: RegExpMatchArray | null = this.getReferrer().match(/:\/\/(.[^/]+)/);
            let referrer: string = regex !== null ? regex[1] : '';
            referrer = referrer.replace(/^(www\.)/, '');
            if (referrer === '') {
                return true;
            }
            const referrerWww: string = 'www.' + referrer;
            for (const entry of uiAppSettings.permissions.iframeAllowedParents) {
                if (entry === '*' || (entry.startsWith('*.') && referrer.toLocaleLowerCase().indexOf(entry.replace('*.', '')) !== -1)) {
                    return true;
                }
            }
            return (contains(uiAppSettings.permissions.iframeAllowedParents, referrer.toLocaleLowerCase()) ||
                    contains(uiAppSettings.permissions.iframeAllowedParents, referrerWww.toLocaleLowerCase()));
        }

        return true;
    }

    protected async lockCameraToFixedTarget(force?: boolean): Promise<void> {
        if (!this.state.settings.camera.lockToTarget && !force) {
            return;
        }

        const mainArticle: MainArticleElement | null = this.app.articleInformationManager.state.mainElement;
        if (mainArticle == null) {
            return;
        }

        await mainArticle.waitUntilGeometryUpdated();
        let boundingBox: BoundingBox;
        if (this.app.sceneManager.state.articleCount > 1) {
            boundingBox = BoundingBox.FromElements(this.app.coreApp.model.elements);
        } else {
            boundingBox = (mainArticle as SceneElement).boundingBox;
        }
        if (!boundingBox.isValid) {
            return;
        }

        this.app.coreApp.viewer.view.cameraControl.setFixedTarget(boundingBox.getCenter());
        this.app.coreApp.viewer.view.cameraControl.panningEnabled = false;
    }

    protected async restrictRotationToZAxis(): Promise<void> {
        if (!this.state.settings.camera.restrictRotationToZAxis) {
            return;
        }
        await this.lockCameraToFixedTarget(true);
        this.app.coreApp.viewer.view.cameraControl.pitchMin = degToRad(20);
        this.app.coreApp.viewer.view.cameraControl.pitchMax = degToRad(20);
    }

    protected getInitialState(): UiManagerState {
        return {
            active: false,
            gatekeeperId: null,
            permissions: {
                pricing: false,
                cadFormats: null,
                allowMultipleManufacturerPEC: true,
                ociExport: false,
                pdfDownload: false,
                iframeAllowedParents: null,
                postMessageContactForm: false,
                showUrlsInCatalog: true,
            },
            settings: {
                general: {
                    pim,
                    pimJSON,
                    lang: Object.keys(LanguageMapping),
                    ofmlLang: undefined,
                    lengthUnit: 'Meter',
                    pdfTemplate: 'pCon_ui_Standard',
                    catalogRootAllowed: true,
                    userTracking: false,
                    customParentTracking: true,
                    enableIframeResizer: false,
                    fullscreenOnly: false,
                    ociVersion: '4',
                    useOldOci: false,
                    generateContactRequestPdf: false,
                    propertyServiceUrl: undefined,
                    migrateArticles: false
                },
                pricing: {
                    calculationScheme: 'STDB2B_FACTS',
                    currency: 'EUR',
                    currencyLeft: CurrencyLeft,
                    tax: 'DE',
                    showPrice: true,
                    netPrice: true,
                    priceServiceUrl: undefined
                },
                report: {
                    templateName: 'pCon_facts_ProductSheet',
                    enablePricing: false,
                    enforcePricing: false,
                    footerText: '',
                    logoUrl: ''
                },
                camera: {
                    restrictRotationToZAxis: false,
                    lockToTarget: false,
                    alpha: null,
                    beta: null,
                    fov: undefined
                }
            },
            visibility: {
                general: {
                    header: true
                },
                articleData: {
                    description: true,
                    manufacturerInSubInfo: ManufacturerInSubInfo,
                    seriesInSubInfo: SeriesInSubInfo,
                    articleNoInSubInfo: ArticleNoInSubInfo,
                    showUneditableProperties: false,
                    enableMetatypeProperties: true,
                    showArticleName: true,
                    showPropertyHeaders: true,
                },
                buttons: []
            },
            defaults: {
                lang: Object.keys(LanguageMapping),
                showPrice: true,
                netPrice: true,
                header: true,
                fullscreenOnly: false,
                restrictRotationToZAxis: false,
                lockToTarget: false,
                description: true,
                manufacturerInSubInfo: ManufacturerInSubInfo,
                seriesInSubInfo: SeriesInSubInfo,
                articleNoInSubInfo: ArticleNoInSubInfo,
                showArticleName: true,
                showPropertyHeaders: true,
                showUneditableProperties: false,
                enableMetatypeProperties: true,
            },
            theme: UiThemeType.DEFAULT,
            styles: {
                cssImport: CSSImport,
                paletteImport: PaletteImport,
                mainPrimaryColor: undefined,
                background: 'white',
                iframeHeightSmall,
                articleDrawerVariant: ArticleDrawerVariant.SINGLE,
                propertyEditorVariant: PropertyEditorVariant.FULL,
            }
        };
    }

    @bind
    protected onViewCreated(): void {
        this.app.articleInformationManager.onArticleChange.addListener(() => { void this.lockCameraToFixedTarget(); });
        this.app.articleInformationManager.onArticleChange.addListener(() => { void this.restrictRotationToZAxis(); });
        this.app.coreApp.viewer.view.cameraControl.eventCameraModeChanged.addListener(() => { void this.lockCameraToFixedTarget(); });
        this.app.coreApp.viewer.view.cameraControl.eventCameraModeChanged.addListener(() => { void this.restrictRotationToZAxis(); });
        this.app.events.viewCreated.removeListener(this.onViewCreated);
    }

    @bind
    private filterButtonsForFullscreen(button: UiButton): boolean {
        if (button.type === 'fullscreen') {
            return true;
        }

        return ALL_BUTTONS_IN_FULLSCREEN === true || button.showInFullscreen === true ;
    }

    @bind
    private updateCurrencyStyle(currencyLeft: boolean | undefined): void {
        setDefaultCurrencyStyle(currencyLeft ? CurrencyStyle.INFRONT : CurrencyStyle.DEFAULT);
    }

    @bind
    private getReferrer(): string {
        const sessionStorageKey: string = this.app.props.gatekeeperId + '-referrer';
        if (isNotNullOrEmpty(document.referrer)) {
            try {
                sessionStorage.setItem(sessionStorageKey, document.referrer);
            } catch (error) {
                // nothing to do here as this step is optional
            }
            return document.referrer;
        } else {
            try {
                return sessionStorage.getItem(sessionStorageKey) ?? '';
            } catch (error) {
                return '';
            }
        }
    }

    private initializeState(stateFromSettings: UiManagerState | undefined): void {
        const initState: UiManagerState = toJS(this.state);
        if (getEnvBoolean('apcs', false) && this.getSessionStorageStateOverride() != null) {
            merge(initState, this.getSessionStorageStateOverride());
        } else {
            merge(initState, stateFromSettings);
        }
        this.updateState({
            active: true,
            gatekeeperId: this.app.props.gatekeeperId,
            permissions: initState.permissions,
            settings: initState.settings,
            visibility: initState.visibility,
            defaults: initState.defaults,
            theme: initState.theme,
            styles: initState.styles
        });
    }

    private getSessionStorageStateOverride(): UiManagerState | null {
        const sessionStorageKey: string = this.app.props.gatekeeperId + '-customSettings';
        try {
            const uiManagerState: string | null = sessionStorage.getItem(sessionStorageKey);
            return isNotNullOrEmpty(uiManagerState) ? (safelyParseJSON(uiManagerState)!) : null;
        } catch (error) {
            return null;
        }
    }

    private shouldSkipActivation(stateFromSettings: UiManagerState | undefined): boolean {
        if (
            stateFromSettings == null ||
            !this.iframeSourceValid(stateFromSettings)
        ) {
            return true;
        }

        const catalogRootPath: Array<string> = CatalogRootPath.length > 0 ? CatalogRootPath : CatalogEntryPath;

        const isCatalogEntry: boolean = this.app.startupActions.isCatalogEntry();

        if (
            (isCatalogEntry || (!isCatalogEntry && stateFromSettings.visibility.general.header))
            && !stateFromSettings.settings.general.catalogRootAllowed && catalogRootPath.length === 0 && !ForceCatalogRoot
        ) {
            return true;
        }

        return false;
    }
}