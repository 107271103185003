import {
    action,
    observe,
    observable,
    IObservableValue,
    computed,
    runInAction,
    makeObservable,
    toJS
} from 'mobx';

import App from '../App';

import { Reader, Writer } from '../PathAccessors';

import { assert } from '@egr/xbox/utils/Debug';
import { developmentMode } from '@egr/xbox/utils/ReactScriptHelper';
import { ListenableEvent } from '@easterngraphics/wcf/modules/utils';
import { safelyParseJSON } from '@egr/xbox/utils/Json';

export type ValueSetter<T> = (value: T) => void;

const AllKnownRootRefs = new WeakSet();
export function isRootStateRef(value: unknown) {
    if (value == null || typeof value !== 'object') {
        return false;
    }

    return AllKnownRootRefs.has(value);
}

export abstract class StateManager<State extends {}, ProtectedState extends {} = {}> {
    /**
     * This event must be used if it is necessary to observe any change (independent of properties) in the state.
     * It is not possible to observe the state object directly (because it is replaced if the `resetState` function is called).
     * However, state properties can be observed normally.
     */
    public readonly onChange: ListenableEvent<
        void,
        StateManager<State, ProtectedState>
    > = new ListenableEvent<void, StateManager<State, ProtectedState>>(this);

    private readonly stateRef: IObservableValue<Readonly<State & ProtectedState> | undefined>;

    @computed
    public get state(): Readonly<State> {
        return this.stateRef.get()!; // FixMe
    }

    protected get protectedState(): Readonly<State & ProtectedState> {
        return this.stateRef.get()!; // FixMe
    }

    public constructor() {
        makeObservable(this);
        this.stateRef = observable.box<Readonly<State & ProtectedState>>(undefined, {deep: false});

        let dispatch: (() => void) | undefined;
        observe(this.stateRef, (): void => {
            if (dispatch !== undefined) {
                dispatch();
            }
            dispatch = observe(this.state, (): void => {
                this.triggerChange();
            });
            this.triggerChange();
        });

        runInAction((): void => {
            this.stateRef.set(this.markAsRootState(observable(this.getInitialState())));
        });

        this.resetState = this.resetState.bind(this);
    }

    @action
    public resetState(): void {
        // Do not use `Object.assign` (or the `updateState` function)
        // to ensure that optional properties (not present in the return
        // value of `getInitialState`) will be removed
        this.stateRef.set(this.markAsRootState(observable(this.getInitialState())));
    }

    /** @internal */
    public _printState(): void {
        console.table(safelyParseJSON(JSON.stringify(this.state)));
    }

    @action
    protected updateState(data: Partial<State & ProtectedState>): void {
        Object.assign(this.state, data);
    }

    private markAsRootState<T extends object>(obj: T): T {
        // Note: using a `Symbol` does not work since the spread operator (`...`)
        // will also copy the symbol to the new instance; therefore a global
        // WeakSet is used instead.
        AllKnownRootRefs.add(obj);
        return obj;
    }

    protected getSetter<K extends keyof (State & ProtectedState)>(key: K): (value: (State & ProtectedState)[K]) => void {
        return action((value: (State & ProtectedState)[K]): void => {
            (this.state as (State & ProtectedState))[key] = value;
        });
    }

    /**
     * If the value-parameter of the setter function is undefined the states prop is set to defaultValue.
     * @param key
     * @param defaultValue
     */
    protected getFallbackSetter<K extends keyof (State & ProtectedState)>(
        key: K, defaultValue: (State & ProtectedState)[K]
    ): (value: undefined | (State & ProtectedState)[K]) => void {
        return action((value: undefined | (State & ProtectedState)[K]): void => {
            if (value === undefined) {
                (this.state as (State & ProtectedState))[key] = defaultValue;
            } else {
                (this.state as (State & ProtectedState))[key] = value;
            }
        });
    }

    protected abstract getInitialState(): Readonly<State & ProtectedState>;

    protected triggerChange: () => void = (): void => {
        this.onChange.trigger();
    };
}

export abstract class AppStateManager<State extends {}, AppInterface extends App = App, ProtectedState extends {} = {}> extends StateManager<State, ProtectedState> {
    @observable
    public appAvailable: boolean = false;

    protected app: AppInterface;

    public constructor() {
        super();

        makeObservable(this);
        this.app = undefined!;

        if (developmentMode) {
            window.setTimeout(
                (): void => {
                    assert(this.app != null, `Manager ${this.constructor.name} must be initialized!`);
                },
                20000
            );
        }
    }

    public initialize(app: AppInterface, ...args: Array<unknown>): void {
        this.app = app;
        runInAction(
            () => { this.appAvailable = true; }
        );
    }

    // Note: the type of the `app` property is not correct (missing undefined) and should be changed.
    // Then `app` could also be changed into `public readonly`.
    // However, since this would mean too many changes at the moment, this function will provide a public getter with
    // the correct return type.
    public getApp(): AppInterface | undefined {
        return this.app;
    }
}

export abstract class AppManager<AppInterface extends App = App> extends AppStateManager<{}, AppInterface> {
    protected getInitialState(): {} {
        return {};
    }
}

export class ToggleManager<State extends {[P in keyof State]: boolean}> extends StateManager<Partial<State>> {
    public get asList(): Array<keyof State> {
        const entries: Array<[string, boolean]> = Object.entries<boolean>(toJS(this.state) as Record<string, boolean>);
        return entries.filter(value => value[1]).map(value => value[0] as keyof State);
    }

    public constructor(protected readonly initializeState: Partial<State> = {}) {
        super();

        // Note: we have to reset the state because the `initializeState` property is
        // set after the `super` call
        // Note: if an initializeState is provided the `console.log` output will not always
        // show the default state but accessing the property will work (or using the `$mbox` property)
        this.resetState();
    }

    // Note: a lambda function was used because this functionality probably does
    // not need to be customized (child class) and is often used as a callback.
    public toggle = (key: keyof State): void => {
        this.updateState({
            [key]: this.state[key] === undefined || this.state[key] === false
        } as Partial<State>);
    };

    public setValues = (keys: Array<keyof State>, value: boolean): void => {
        const partial: Partial<{[P in keyof State]: boolean}> = {};
        for (const key of keys) {
            partial[key] = value;
        }
        this.updateState(partial as Partial<State>);
    };

    protected getInitialState(): State {
        return Object.assign({}, this.initializeState) as State;
    }
}

export interface StateTransitionCallbacks<T> {
    restore(value: T | undefined, reader: Reader): Promise<void> | void;
    dump(write: Writer): Promise<T | undefined> | T | undefined;
}

export interface ObservableStateTransitionCallbacks<T> {
    restore(value: T | undefined): Promise<void> | void;
    dump(): T | undefined;
}

export type PersistentStateDump = Map<string, unknown>;

interface SelectionManagerState<ItemType> {
    selection: ReadonlyArray<ItemType>;
    enabled: boolean;
}

export class SelectionManager<ItemType> extends StateManager<SelectionManagerState<ItemType>> {
    constructor() {
        super();
        makeObservable(this);
        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.setSelection = this.setSelection.bind(this);
    }

    @computed
    public get selection(): ReadonlyArray<ItemType> {
        return this.state.selection;
    }

    public enable(): void {
        this.updateState({enabled: true});
    }

    public disable(): void {
        this.updateState({enabled: false});
    }

    public isSelected(value: ItemType): boolean {
        return this.state.enabled && this.state.selection.indexOf(value) !== -1;
    }

    public toggle(): void {
        const enabled: boolean = this.state.enabled === false;
        if (enabled) {
            this.updateState({enabled});
        } else {
            this.updateState({enabled});
        }
    }

    public setSelection(selection: ReadonlyArray<ItemType>): void {
        this.updateState({selection});
    }

    public toggleSelection(value: ItemType): void {
        const currentSelection: ReadonlyArray<ItemType> = this.selection;
        if (currentSelection.indexOf(value) === -1) {
            this.updateState({selection: [...this.selection, value]});
        } else {
            const selection: Array<ItemType> = this.selection.filter((selectionValue: ItemType) => {
                return selectionValue !== value;
            });
            this.updateState({selection, enabled: selection.length !== 0});
        }
    }

    protected getInitialState(): SelectionManagerState<ItemType> {
        return {
            selection: [],
            enabled: false,
        };
    }
}