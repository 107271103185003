import bind from 'bind-decorator';
import { observable, IObservableValue, runInAction } from 'mobx';

import {
    DEBUG_MATOMO_TRACKING,
    MATOMO_VISIT_DIMENSION_APP, MATOMO_DIMENSION_APP,
    MATOMO_TRACKING_URL, MATOMO_TRACKING_ID,
    DefaultDownloadExtensions, AdditionalDownloadExtensions
} from './TrackingConstants';
import { SupportedTrackingEvents } from './TrackingEvents';
import { updateOrientation, setFullscreenDimension, updateCustomUrl, setDownloadExtensions } from './TrackingUtils';

import { Logger } from '@egr/xbox/utils/errors/Logger';
import { appInfo } from '@egr/xbox/app-api/AppInfo';
import { assert } from '@egr/xbox/utils/Debug';
import { developmentMode } from '@egr/xbox/utils/ReactScriptHelper';
import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { isNotNullOrUndefined, loadScript } from '@egr/xbox/utils/Helper';
import { notNull } from '@egr/xbox/utils/Types';
import { addBreadcrumb, captureException } from '../utils/Error';

export function pushEventData(value: Array<unknown>): void {
    value = value.filter(isNotNullOrUndefined);

    if (DEBUG_MATOMO_TRACKING) {
        Logger.log(value);
    }

    try {
        addBreadcrumb({
            category: 'telemetry',
            message: JSON.stringify(value)
        });

        if (
            typeof window._paq !== 'undefined' &&
            (
                // cache only the first 100 events before the tracking has to be enabled
                // (the .length propery is undefined after the tracking was enabled)
                window._paq.length == null ||
                window._paq.length < 100
            )
        ) {
            window._paq.push(value);
        }
    } catch (error) {
        captureException(error, 'debug');
    }
}

declare global {
    interface Window {
        _paq?: Array<Array<unknown>> | {push: (data: Array<unknown>) => void, length: null};
    }
}

if (window._paq == null) {
    window._paq = [];
}

export function setCustomDimension(dimension: number | undefined, value: string): void {
    if (dimension != null) {
        pushEventData(['setCustomDimension', dimension, value]);
    }
}

export const matomoActive: IObservableValue<boolean> = observable.box(false);

export function disableTracking(): void {
    orientationDisposer?.();
    pushEventData(['forgetUserOptOut']);
    runInAction((): void => {
        matomoActive.set(false);
    });
}

let orientationDisposer: (() => void) | undefined;
function addOrientationListener(callback: () => void) {
    orientationDisposer?.();
    window.addEventListener('orientationchange', callback);
    orientationDisposer = () => {
        window.removeEventListener('orientationchange', callback);
        orientationDisposer = undefined;
    };
}

// Note: cookies will be disabled if the `visitorId` is padded into this function
export async function enableTracking(visitorId?: string, startView?: string): Promise<void> {
    if (isNotNullOrEmpty(visitorId)) {
        pushEventData(['disableCookies']);
        pushEventData(['setVisitorId', visitorId]);
    }

    // set the dimensions before the first page hit is tracked
    updateOrientation();

    setCustomDimension(MATOMO_DIMENSION_APP, appInfo != null ? 'app' : 'web');
    setCustomDimension(MATOMO_VISIT_DIMENSION_APP, appInfo != null ? 'app' : 'web');
    setDownloadExtensions(new Set([...DefaultDownloadExtensions, ...AdditionalDownloadExtensions]));

    updateCustomUrl(startView);

    pushEventData(['enableLinkTracking']);

    if (MATOMO_TRACKING_ID !== '' && MATOMO_TRACKING_URL !== '') {
        pushEventData(['setTrackerUrl', MATOMO_TRACKING_URL + 'matomo.php']);
        pushEventData(['setSiteId', MATOMO_TRACKING_ID]);
        pushEventData(['forgetUserOptOut']);

        assert(window._paq!.length != null, '_paq should be an array');
        try {
            await loadScript(
                MATOMO_TRACKING_URL + 'matomo.js',
                true
            );
            assert(window._paq!.length == null, '_paq should be replace');

            runInAction((): void => {
                matomoActive.set(true);
            });

            addOrientationListener(updateOrientation);
        } catch (error) {
            Logger.error(error);
        }
    } else {
        disableTracking();
    }
}

// eslint-disable-next-line
(window as any)['enableTracking'] = enableTracking;
// eslint-disable-next-line
(window as any)['disableTracking'] = disableTracking;

export function trackEvent(data: SupportedTrackingEvents): void {
    pushEventData(['trackEvent', data.category, data.action, data.name, data.value].filter(notNull));

    if (data.category === 'Full Screen') {
        setFullscreenDimension(data.action === 'enter');
    }
}

type TelemetryEventBuilder = (data: Partial<SupportedTrackingEvents>) => SupportedTrackingEvents | undefined;

export class TelemetryEventQueue {
    private readonly events: Array<SupportedTrackingEvents> = [];

    /**
     * @param {TelemetryEventBuilder} eventBuild - This callback is used to when calling
     *   the {@link TelemetryEventQueue.addPartialEvent} method. It can enrich or modify
     *   the provided data.
     *   It must return a valid event or `undefined` if the event should be discarded.
     */
    public constructor(private eventBuild: TelemetryEventBuilder) {
    }

    @bind
    public addEvent(data: SupportedTrackingEvents): void {
        this.events.push(data);
    }

    /**
     * This function can be used if some properties of the tracking event must
     * be provided outside of the scope which generates the event which should be
     * tracked
     *
     * @param data
     */
    @bind
    public addPartialEvent(data: Partial<SupportedTrackingEvents>) {
        const eventData = this.eventBuild?.(data);
        if (eventData != null) {
            this.addEvent(eventData);
        } else if (developmentMode) {
            console.warn('discarded partial tracking event', data);
        }
    }

    @bind
    public send(): void {
        this.events.forEach(trackEvent);
        this.events.length = 0; // clear all events
    }
}