import sortBy from 'lodash/sortBy';

import { DATA_LANGUAGES } from '@egr/xbox/base-app/managers/SessionManager';
import { GatekeeperManager } from '@egr/xbox/base-app/managers/GatekeeperManager';

import { unique } from '@easterngraphics/wcf/modules/utils/array';

export class SessionManager extends GatekeeperManager {
    public constructor(protected languages: Array<string>) {
        super();
    }

    public override async setLanguage(primaryLanguage: string | null = null): Promise<void> {
        const languages: Array<string> = this.getSortedDataLanguages(primaryLanguage);
        await this.app.eaiwsSession.catalog.setLanguages(languages);
        await this.app.eaiwsSession.basket.setLanguages(languages);
        this.onChange.trigger();
    }

    protected override getSortedDataLanguages(primaryLanguage: string | null = null): Array<string> {
        let languages: Array<string> = this.languages;
        if (primaryLanguage != null) {
            languages = unique([primaryLanguage, ...languages, ...DATA_LANGUAGES]);
        }
        return sortBy(
            languages,
            (value: string): number => {
                const pos: number = languages.indexOf(value);
                if (pos === -1) {
                    return languages.length + 1;
                }

                return pos;
            }
        );
    }
}