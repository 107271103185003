import { AsyncFunction } from './Types';
import { doNothing } from './Helper';
import { getPromiseCallbacks } from './promise/Callbacks';
import { captureException } from './Error';

import { CustomError } from '@easterngraphics/wcf/modules/utils/error';
import { Lock, LockContext } from '@easterngraphics/wcf/modules/utils/async';

function resolveImmediately(): Promise<void> {
    return Promise.resolve();
}

/**
 * @param delay in ms
 */
export function timeout(delay: number = 0): Promise<void> {
    return new Promise<void>((resolve: () => void, reject: (error: Error) => void): void => {
        window.setTimeout(resolve, delay);
    });
}

export class TaskCanceledError extends CustomError {
    public constructor(message?: string) {
        super(message);

        Object.setPrototypeOf(this, TaskCanceledError.prototype);
    }
}

const defaultErrorGenerator: () => never = (): never => {
    throw new TaskCanceledError('Task was canceled');
};

export interface AbortableAction {
    addCallback: (cb: VoidFunction) => void;
    abort: VoidFunction;
    check: VoidFunction;
}

export function getAbortableAction(callback?: VoidFunction): AbortableAction {
    let aborted: boolean = false;

    const callbacks: Array<VoidFunction> = [];
    if (callback != null) {
        callbacks.push(callback);
    }

    return {
        addCallback: (cb: VoidFunction): void => {
            callbacks.push(cb);
        },
        abort: (): void => {
            aborted = true;
            callbacks.forEach(cb => cb());
        },
        check: (): void => {
            if (aborted) {
                throw new TaskCanceledError();
            }
        }
    };
}

class Queue {
    private queueEnd: Promise<void> = Promise.resolve();
    private cancelErrorGenerator: (() => never) | null = null;

    public async add<T = void>(callback: () => Promise<T>): Promise<T> {
        const queueEntry: Promise<void> = this.queueEnd;

        const {promise, resolve} = getPromiseCallbacks<void>();
        this.queueEnd = promise;

        await queueEntry;

        try {
            if (this.cancelErrorGenerator) {
                this.cancelErrorGenerator();
            }

            const value: T = await callback();

            if (this.cancelErrorGenerator) {
                this.cancelErrorGenerator();
            }

            return value;
        } finally {
            window.setTimeout(resolve, 0);
        }
    }

    /**
     * This will call the `errorGenerator` function before and after each
     * task was executed
     *
     * Note: the task, which is already running, will only be canceled after
     * its termination.
     *
     * @param errorGenerator a function witch throws an Error on every call
     */
    public async cancel(errorGenerator: () => never = defaultErrorGenerator): Promise<void> {
        this.cancelErrorGenerator = errorGenerator;

        try {
            await this.add((): Promise<void> => {
                return Promise.resolve();
            });
        } catch (error) {
            // do nothing
        } finally {
            this.cancelErrorGenerator = null;
        }
    }
}

class ThrottledQueue extends Queue {
    protected timeoutGenerator: () => Promise<void>;

    public constructor(delay: number) {
        super();

        this.timeoutGenerator = (): Promise<void> => {
            return timeout(delay);
        };
    }

    public override async add<T = void>(callback: () => Promise<T>): Promise<T> {
        const task: Promise<T> = super.add<T>(callback);
        super.add(this.timeoutGenerator).catch(doNothing);

        return task;
    }
}

export class Bouncer<T extends Array<unknown> = []> {
    protected pending: boolean = false;
    protected pendingValue: T;
    protected running: boolean = false;
    protected queue: ThrottledQueue;

    public constructor(protected callback: (...args: T) => Promise<void>, delay: number) {
        this.queue = new ThrottledQueue(delay);
    }

    public trigger: (...data: T) => void = (...data: T): void => {
        this.pendingValue = data;

        if (!this.running) {
            void this.startTask(); // Note: do not await
        } else if (!this.pending) {
            this.pending = true;
        }
    };

    protected startTask: () => Promise<void> = (): Promise<void> => {
        this.pending = false;
        this.running = true;

        const task: Promise<void> = this.queue.add<void>((): Promise<void> => this.callback(...this.pendingValue));

        // Note: it is important that the `endTask` is added immediately after the `startTask`

        void this.queue.add(this.endTask);

        return task;
    };

    protected endTask: () => Promise<void> = (): Promise<void> => {
        this.running = false;

        if (this.pending) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            window.setTimeout(this.startTask);
        }

        return Promise.resolve();
    };
}

/**
 * Should only be used when absolutely necessary, as it removes the advantages of AsyncIterableIterator.
 * (A valid use case is, for example, sorting the results.)
 *
 * The `tick` callback should throw an error if the action should be aborted.
 */
export async function AsyncIteratorToArray<T>(iterator: AsyncIterableIterator<T>, tick: VoidFunction = doNothing): Promise<Array<T>> {
    const result: Array<T> = [];
    for await (const value of iterator) {
        tick();

        result.push(value);
    }

    return result;
}

export function animationFrame(cb: VoidFunction): Promise<void> {
    return new Promise((resolve: VoidFunction): void => {
        requestAnimationFrame((): void => {
            window.setTimeout(resolve);
            cb();
        });
    });
}

export const ignoreRejection = (promise: Promise<void>) => rejectValue(promise, undefined, true);
export async function rejectValue<T>(promise: Promise<T>, fallback: T, captureError: boolean = false): Promise<T> {
    try {
        return await promise;
    } catch (error) {
        if (captureError) {
            captureException(error, 'debug');
        }

        return fallback;
    }
}

export async function fallbackAction<T = void>(
    action: AsyncFunction<T>,
    fallback: AsyncFunction<T>,
    fallbackDelay: number,
    failTimeout?: number
): Promise<T> {
    return new Promise<T>((resolve: (value: T) => void, reject: (error: Error) => void): void => {
        let finished: boolean = false;
        let fallbackStarted: boolean = false;
        let finalReject: boolean = false;

        const callReject: (error: Error) => void = (error: Error): void => {
            if (finalReject && !finished) {
                finished = true;
                reject(error);
            } else {
                finalReject = true;
            }
        };

        const callResolve: (value: T) => void = (value: T): void => {
            if (!finished) {
                finished = true;
                resolve(value);
            }
        };

        const startFallback: VoidFunction = (): void => {
            if (!fallbackStarted && !finished) {
                fallbackStarted = true;

                fallback().then(callResolve).catch(callReject);
            }
        };

        action().then(callResolve).catch((error: Error): void => {
            startFallback();
            callReject(error);
        });

        void timeout(fallbackDelay).then(startFallback);

        if (failTimeout != null) {
            void timeout(failTimeout).then((): void => {
                finalReject = true;
                callReject(new Error('Timeout reached'));
            });
        }
    });
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type PromiseMethodDecorator<T = any> = (
    target: object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>>
) => TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>> | void;

export function limitConcurrentCalls<T = any>(lock: Lock = new Lock(1)): PromiseMethodDecorator<T> {
    return (
        target: object,
        propertyKey: string | symbol,
        descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>>
    ) => {
        if (descriptor.value != null) {
            const method: ((...args: Array<any>) => Promise<T>) | undefined = descriptor.value;
            descriptor.value = async function(...args: Array<any>): Promise<T> {
                const context: LockContext = await lock.acquireLock();
                try {
                    return await method.apply(this, args);
                } finally {
                    context.unlock();
                }
            };
        }
        return descriptor;
    };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function ignoreCallsWhileStillRunning<T extends Array<unknown>>(
    target: object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: T) => Promise<void>>
): TypedPropertyDescriptor<(...agrs: T) => Promise<void>> {
    if (descriptor.value != null) {
        let running: boolean = false;

        const method: ((...args: T) => Promise<void>) | undefined = descriptor.value;
        descriptor.value = async function(...args: T): Promise<void> {
            if (!running) {
                running = true;
                try {
                    await method.apply(this, args);
                } finally {
                    running = false;
                }
            }
        };
    }
    return descriptor;
}

export function startInterval(callback: AsyncFunction<void>, delay: number, startImmediately: boolean = false): AsyncFunction {
    const queue: ThrottledQueue = new ThrottledQueue(delay);
    let canceled: boolean = false;
    let running: boolean = false;

    const run: AsyncFunction<void> = async (): Promise<void> => {
        if (canceled === false) {
            try {
                running = true;
                await callback();
            } finally {
                running = false;
                void queue.add(run);
            }
        }
    };

    if (startImmediately === false) {
        void queue.add(resolveImmediately);
    }

    void queue.add(run);

    return (): Promise<void> => {
        canceled = true;

        if (running === false) {
            return Promise.resolve();
        }

        return queue.add(resolveImmediately);
    };
}
