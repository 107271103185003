import { AppName } from '@egr/xbox/utils/Constants';
import { getEnvArray, getEnvVar, getEnvBoolean } from '@egr/xbox/utils/ReactScriptHelper';

export const ExperimentalFeatures: boolean = getEnvBoolean('ENABLE_EXPERIMENTAL_FEATURES', false);
export const DisableCloud: boolean = getEnvBoolean('DISABLE_CLOUD', false);
export const EaiwsStartup: string = getEnvVar('EAIWS_STARTUP', '');
export const AdminPanel: boolean = getEnvBoolean('ap', false);

export const CurrencyLeft: boolean = getEnvBoolean('CURRENCY_LEFT', false);

export const HookUrl: string = getEnvVar('HOOK_URL', '');
export const CustomFields: string = getEnvVar('customFields', '');
export const ManufacturerOrCatalog: string = getEnvVar('moc', '');
export const BaseArticleNumber: string = getEnvVar('ban', '');
export const SeriesId: string = getEnvVar('sid', '');
export const PecSearchString: string = getEnvVar('pss', '');
export const ObxUrl: string = getEnvVar('obx', '');
export const OfmlVariantCode: string = getEnvVar('ovc', '');

export const CatalogRootPath: Array<string> = getEnvArray('crp');
export const CatalogEntryPath: Array<string> = getEnvArray('cep');

export const ForceCatalogRoot: boolean = getEnvBoolean('fcr', false);
export const productName: string = AppName ?? '';

export const ArticleNoInSubInfo: boolean = true;
export const SeriesInSubInfo: boolean = true;
export const ManufacturerInSubInfo: boolean = true;
export const showArticleName: boolean = true;
export const EnableMetatypeProperties: boolean = true;

export const IsPlanner: boolean = navigator.userAgent.toLocaleLowerCase().indexOf('w-cclient') !== -1 &&
    navigator.userAgent.toLocaleLowerCase().indexOf('p-pl-x') !== -1;
export const IsRevit: boolean = navigator.userAgent.toLocaleLowerCase().indexOf('p_cat_rvt') !== -1;

export const LanguageMapping: Record<string, { name: string, locale: string}> = {
    en: {name: 'English', locale: 'en_US'},
    de: {name: 'Deutsch', locale: 'de_DE'},
    da: {name: 'Dansk', locale: 'da_DK'},
    es: {name: 'Español', locale: 'es_ES'},
    fr: {name: 'Français', locale: 'fr_FR'},
    it: {name: 'Italiano', locale: 'it_IT'},
    nl: {name: 'Nederlands', locale: 'nl_NL'},
    pl: {name: 'Polskie', locale: 'pl_PL'},
    pt: {name: 'Português', locale: 'pt_PT'},
    ro: {name: 'Română', locale: 'ro_RO'},
    tr: {name: 'Türkçe', locale: 'tr_TR'},
    sv: {name: 'Svenska', locale: 'sv_SE'},
    cs: {name: 'Český', locale: 'cs_CZ'},
    hu: {name: 'Magyar', locale: 'hu_HU'}
};

export const iframeHeightSmall: string = getEnvVar('IFRAME_HEIGHT_SMALL', '166%');

export const PaletteImport: string | undefined = getEnvVar('PALETTE_IMPORT');
export const CSSImport: string | undefined = getEnvVar('CSS_IMPORT');

export const pim: boolean = getEnvBoolean('PIM', false);
export const pimJSON: boolean = getEnvBoolean('PIMJSON', false);